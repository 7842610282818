import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import HeroOrlandoCity from '../../../../pt/us/experience/mls-interco-orlandosc/sections/hero-orlando-city/_index'
import InterCoStadium from '../../../../pt/us/experience/mls-interco-orlandosc/sections/interco-stadium/_index'
import PrimeiroPatrocinador from '../../../../pt/us/experience/mls-interco-orlandosc/sections/primeiro-patrocinador/_index'
import CartaoDeCreditoDebito from '../../../../pt/us/experience/mls-interco-orlandosc/sections//cartao-de-credito-debito/_index'
import FooterUs from 'src/components/Us/FooterUS/_index'

const IntercoOrlandoCityEN = () => {
  const lang = 'en'

  return (
    <Layout pageContext={pageContext}>
      <HeroOrlandoCity lang={lang} />
      <InterCoStadium lang={lang} />
      <PrimeiroPatrocinador lang={lang} />
      <CartaoDeCreditoDebito lang={lang} />
      <FooterUs
        section='dobra_05'
        slugPT={pageContext.pageEN}
        slugEN={pageContext.slug}
        bgColor='bg-grayscale--500'
        lang={lang}
      />
    </Layout>
  )
}

export default IntercoOrlandoCityEN
